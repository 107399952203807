.paginate-container {
  display: inline-block;
  border-radius: .2rem;
  margin: 0.5rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.paginate-container>li {
  display: inline;
  padding: 0.5rem 1rem;
}

/*.paginate-container > li > a {
  display: block;
}*/

.paginate-previous,
.paginate-next,
.paginate-page {
  border-radius: .2rem;
  margin-left: .25rem;
  margin-right: .25rem;
  border: #bbb solid 1px;
}

.paginate-previous:hover,
.paginate-next:hover,
.paginate-page:hover {
  background-color: #bbb;
}

.paginate-page.paginate-active {
  padding: 0.75rem 1.25rem;
}
.paginate-active,
.paginate-active:hover{
  font-weight: 500;
  color: #fff;
  background-color: #00BCD4;
  cursor: default;
}

.paginate-disabled:hover,
.paginate-disabled {
  background-color: #7a7a7a;
  color: #bbb;
}

.paginate-container li {
  cursor: pointer;
}
.paginate-disabled:hover{
  cursor: not-allowed;
}
