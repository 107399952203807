html,body{
  height: 100%;
  position:relative;
  margin: 0;
  padding: 0;
}

.app {
  position: absolute;
  height: 91%;
  width: 100%;
}

/* Styles for View Image Pagination */
.arrow {
  position: absolute;
  top: 50%;
  height: 75px;
  z-index: 10;
  cursor: pointer;
}
.left-arrow {
  left: -1px
}

.right-arrow {
  right: 1px;
}
/* End Styles for View Image Pagination */


/* Utilities */
.clearfix :after { contents:""; display: table; clear: both; }